import Box from '@stackhouseos/box-core';
import Saga from './components/Saga';
import Reset from './components/Reset';
import Text from './components/Text';
import Button from './components/Button';
import BigButton from './components/BigButton';
import FlatList from './components/FlatList';
import Icon from './components/Icon';
import IconButton from './components/IconButton';
import BackButton from './components/BackButton';
import Flex from './components/Flex';
import Image from './components/Image';
import Input from './components/Input';
import View from './components/View';
import Subflow from './components/Subflow';
import Scrollview from './components/Scrollview';
import Select from './components/Select';
import Result from './components/Result';
import Spacer from './components/Spacer';
import Loading from './components/Loading';
import Router from './components/Router';
import Navigate from './components/Navigate';
import CartItem from './components/CartItem';
import CartTotal from './components/Cart/Total';
import CartProductQty from './components/Cart/Qty';
import CartTrash from './components/CartTrash';

Box.extendControls({
  View,
  Scrollview,
  Saga,
  Reset,
  Text,
  Button,
  Result,
  BigButton,
  CartTrash,
  Router,
  Loading,
  Select,
  Navigate,
  FlatList,
  CartItem,
  Subflow,
  Icon,
  IconButton,
  BackButton,
  Flex,
  Input,
  Image,
  Spacer,
  CartTotal,
  CartProductQty,
});
