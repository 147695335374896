import {createSelector} from 'reselect';
import _get from 'lodash/get';

const appSelect = state => state.app;
const accountSelect = state => state.app.account;

export const getCurrentRoute = createSelector(appSelect, substate =>
  _get(substate, 'currentRoute', '/'),
);

export const getBackground = createSelector(accountSelect, substate =>
  _get(substate, 'backgroundImage'),
);
