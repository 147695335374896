import {put, select, takeLatest} from 'redux-saga/effects';
import {getProductsByCategory} from './selectors';
import {actionUpdate} from '@stackhouseos/box-core';
import sortBy from 'lodash/sortBy';
import get from 'lodash/get';

function* load({payload}) {
  const {flowName, routeParams} = payload;
  const products = yield select(getProductsByCategory(routeParams.id));
  const {sortedData} = yield select(state => state.app.account);
  const productsSorted = sortBy(products, item =>
    get(sortedData, ['dishes', routeParams.id], []).indexOf(
      item._id.toString(),
    ),
  );

  yield put(actionUpdate('products', 'filtered', productsSorted));
  yield put({type: 'flow/next', payload: {flowName}});
}

export default function* homeSaga() {
  yield takeLatest('PRODUCTS_FILTER', load);
}
