import {createSelector} from 'reselect';
import _get from 'lodash/get';
import keyBy from 'lodash/keyBy';

const productsSelect = state => state.products;

export const getProductsItems = createSelector(productsSelect, substate =>
  _get(substate, 'items', []),
);

export const getProductsItemsIds = createSelector(productsSelect, substate =>
  keyBy(_get(substate, 'items', []), '_id'),
);

export const getProductsByCategory = category =>
  createSelector(getProductsItems, products => {
    return products.filter(rec => rec.categories.includes(category));
  });
