import {createSlice} from '@reduxjs/toolkit';
import set from 'lodash/set';

const initialState = {
  items: [],
};

const slice = createSlice({
  name: 'categories',
  initialState,
  reducers: {},
  extraReducers: {
    '@box/categories/update': (state, action) =>
      set(state, action.payload.id, action.payload.value),
  },
});

export const actions = slice.actions;
export default slice.reducer;
