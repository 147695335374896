import {all} from 'redux-saga/effects';
import {flowSaga} from '@stackhouseos/flow-client';
import App from '../containers/App/saga';
import Categories from '../containers/Categories/saga';
import Cart from '../containers/Cart/saga';
import Products from '../containers/Products/saga';

export default function* root() {
  const sagas = [App(), Categories(), Products(), Cart(), flowSaga()];
  yield all(sagas);
}
