import React, {Suspense, forwardRef, Fragment} from 'react';
import {StyleSheet, ImageBackground, View} from 'react-native';

import Flow from '@stackhouseos/flow-client';
import {useSelector} from 'react-redux';
import {Router} from '@reach/router';

import flow from './app.flow.json';
import Loading from './components/Loading';
import {getBackground} from './containers/App/selectors';
import './initBox';

const Layout = forwardRef((props, ref) => (
  <Fragment>{props.children}</Fragment>
));

const AppMain = React.memo(() => {
  return (
    <Router component={Layout}>
      <Flow
        name={flow.name}
        elements={flow.elements}
        reducerName={'app'}
        path={'/*'}
      />
    </Router>
  );
});

const App = () => {
  const background = useSelector(getBackground);
  const BgComponent = background ? ImageBackground : View;
  return (
    <Suspense fallback={<Loading />}>
      <BgComponent style={styles.main} source={{uri: background}}>
        <AppMain />
      </BgComponent>
    </Suspense>
  );
};

export default App;

const styles = StyleSheet.create({
  main: {flex: 1, backgroundColor: '#FF6D50'},
});
