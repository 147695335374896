import React from 'react';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import 'react-native-gesture-handler';

import store, {persistor} from './store';
import AppRoot from './main';
import {ActivityIndicator} from 'react-native';
import {Devtools} from '@stackhouseos/flow-client';

function HeadlessCheck({isHeadless}) {
  if (isHeadless) {
    return null;
  }

  return (
    <Provider store={store}>
      <PersistGate loading={<ActivityIndicator />} persistor={persistor}>
        <AppRoot />
      </PersistGate>
    </Provider>
  );
}

Devtools({port: 8771});

export default HeadlessCheck;
