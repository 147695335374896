import {createSlice} from '@reduxjs/toolkit';
import set from 'lodash/set';

const initialState = {};

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setCurrentRoute: (state, {payload}) => {
      state.currentRoute = payload;
    },
  },
  extraReducers: {
    '@box/app/update': (state, action) =>
      set(state, action.payload.id, action.payload.value),
  },
});

export const actions = slice.actions;
export default slice.reducer;
